import React, { useState } from 'react';
import Modal from 'react-modal';
import './Impressum.css';

Modal.setAppElement('#root'); // This line is important for accessibility reasons.

const Impressum = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <button className="impressum-button" onClick={openModal}>Impressum</button>
      <Modal 
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Impressum Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Impressum</h2>
        <h3>Alexander Janowski</h3>
        <p>Talstraße 09</p>
        <p>20359 Hamburg</p>
        <p>&#x73;&#x61;&#x6e;&#x63;&#x74;&#x75;&#x6d;&#x68;&#x68;&#64;&#x67;&#x6d;&#x61;&#x69;&#x6c;.&#x63;&#x6f;&#x6d;</p>
        <button className="impressum-button" onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default Impressum;
