import './App.css';
import logo from './img/logo_flacher.png';
import MainText from './components/MainText/MainText';
import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';
import Impressum from './components/Impressum/Impressum';
import { useState } from 'react';

function App() {
  const [language, setLanguage] = useState('english'); // default language

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };
  return (
    <div className="App">
    <LanguageSwitcher onLanguageChange={handleLanguageChange} />
     <div className="logo-container">
       <div className="logo" style={{backgroundImage: `url(${logo})`}}></div>
     </div>
     <MainText language={language} />
      <Impressum />
     </div>
  );
}

export default App;
