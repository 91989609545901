import React from 'react';
import './MainText.css';
import en from '../../content/text/main/en.json';
import de from '../../content/text/main/de.json';

const MainText = ({ language }) => {
  const translations = language === 'english' ? en : de;

  return (
    <div className="text-container">
      <p>{translations.para1}</p>
      <p>{translations.para2}</p>
      <p>{translations.para3}</p>
      <p>{translations.para4}</p>
      <p>{translations.para5}</p>
      <p>{translations.para6}</p>
      <p>{translations.para7}</p>
    </div>
  );
};

export default MainText;

