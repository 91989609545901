import React from 'react';
import './LanguageSwitcher.css';
import { ReactComponent as EnglishFlag } from '../../img/flags/gb.svg';
import { ReactComponent as GermanFlag } from '../../img/flags/de.svg';

const LanguageSwitcher = ({ onLanguageChange }) => {
  return (
    <div className="language-switcher">
      <EnglishFlag 
        alt="English"
        onClick={() => onLanguageChange('english')}
        className="language-icon"
      />
      <GermanFlag 
        alt="German"
        onClick={() => onLanguageChange('german')}
        className="language-icon"
      />
    </div>
  );
};

export default LanguageSwitcher;
